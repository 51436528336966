<template>
    <div class="page-content">
        <div class="row">
             <img src="https://cdn.joeltankard.com/website/projects/upproval/templates.jpg" />
            <div>
                <h2>How it started</h2>
                <p>I began my journey as a designer at The DSD Studios, which, at that time, was just me and its founder, Duncan Shrimpton. We secured ongoing work for Accor Hotels, focusing on their yearly playbooks, marketing materials, and other print items for all their Australian hotels. However, our success came with challenges. The review process was cumbersome, involving back-and-forth exchanges of PDFs with hotel brand managers for minor edits, proving both time-consuming and costly.</p>
               
            </div>
        </div>
        <div class="row row-reverse">
            <img src="https://cdn.joeltankard.com/website/projects/upproval/comments.jpg" />
            <div>
                <h2>Prototype and release</h2>
                <p>The solution emerged from a prototype I developed named 'Paper.' Despite its simplicity and being coded in a single file, it miraculously worked. Upon presenting it to brand managers, their excitement was palpable, and they eagerly inquired about its availability.</p>
                <p>Over the next six months, I balanced paid agency work with unpaid, intensive development of Upproval. The hard work paid off when we launched and successfully signed up twelve major Accor hotels. We introduced groundbreaking features like tables, links, text-wrapping, an approval process and PDF export – a first in online design tools at the time.</p>
            </div>
             <img src="https://cdn.joeltankard.com/website/projects/upproval/style-kit.jpg" />
                <img src="https://cdn.joeltankard.com/website/projects/upproval/export.jpg" />
        </div>

        <div class="row">
            <img src="https://cdn.joeltankard.com/website/projects/upproval/logos.png" />

            <div>
                <h2>The Rise of Competitors and New Opportunities</h2>
                <p>Our progress faced a new challenge with the emergence of Canva, a tool with impressive capabilities. Around this time, Duncan and I were also invited by Josh Wilson to become the founding team of Grow Super, leading us to gradually shift our focus away from Upproval.</p>
            </div>
        </div>

        <div class="outcome">
            <h2>Outcome</h2>
            <div class="stats">
                <div>
                    <h3>Life span</h3>
                    <h4>5 years</h4>
                </div>
                <div>
                    <h3>Used by</h3>
                    <h4>12 hotels</h4>
                </div>
                <div>
                    <h3>Staus</h3>
                    <h4>Cancelled</h4>
                </div>
            </div>
            <div>
                <h3>Summary</h3>
                <p>Developing Upproval was a transformative experience where I honed my coding skills, particularly in JavaScript, and navigated the challenges of a bootstrapped venture. The tool saw modest success, signing up 12 hotels and achieving profitability, albeit marginally. However, as Canva rose in the market, our focus shifted. We embraced an opportunity to join the founding team of <a href="https://joeltankard.com/projects/grow-super-v2">Grow Super</a>. Despite providing updates for a while, we eventually made the tough decision to close Upproval, marking a pivotal transition in our professional journey.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Upproval'
}
</script>
